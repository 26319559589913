<template>
    <div class="row">
        <div class="col-md-12" v-if="loading">
            <div class="row mt-5 pt-5">
                <div class="col-md-12" v-loading="loading">

                </div>
            </div>
        </div>
        <div class="col-md-12" v-if="!loading">
            <el-table :data="tableData" :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }" style="width: 100%"
                :empty-text="'No Data Available'" height="55vh">
                <el-table-column label="Name" prop="user_id" width="200">
                    <template slot-scope="scope">
                        <p>
                            {{ scope.row.user.name || "Invalid Name" }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="Phone" prop="phone" width="200">
                    <template slot-scope="scope">
                        <p>
                            {{ scope.row.user.phone || "Invalid Phone" }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="Email" prop="email" width="200">
                    <template slot-scope="scope">
                        <p>
                            {{ scope.row.user.email || "Invalid Email" }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="Feedback" prop="feedback" width="">
                </el-table-column>
                <el-table-column label="Rating" width="200">
                    <template slot-scope="scope">
                        <el-rate disabled style="width:100%" v-model="scope.row.rating"></el-rate>
                    </template>
                </el-table-column>
                <el-table-column label="Created On" width="200">
                    <template slot-scope="scope">
                        <p>
                            {{ moment(scope.row.created_at).format('ll') }}
                        </p>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    label="Action"
                    width="200">
                    <template slot-scope="scope">
                        <button id="enter__account" class="approve" @click="view(scope)" >
                            View
                        </button>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
    </div>
</template>

<script>
import store from '@/state/store.js'
import moment from 'moment'
import appConfig from '@/app.config'
export default {
    page: {
        title: 'Feedbacks',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        tableData() {
            return store.getters['admin/feedback/feedbacks']
        }
    },
    methods: {
        view() {

        }
    },
    mounted() {
        this.loading = true
        store.dispatch('admin/feedback/getFeedback').then((data) => {
        }).finally(() => this.loading = false)
    }

}
</script>

<style></style>